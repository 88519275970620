<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-16 09:52:02
-->
<template>
  <div id="page-list">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <div id="components-dropdown-demo-placement">
        <a-dropdown placement="bottomCenter">
          <a-button type="primary"><router-link to="/content/addEdit">新增</router-link></a-button>
          <!-- <a-menu slot="overlay">
            <a-menu-item>
              <router-link to="/content/contentEdit">文章</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/content/mvEdit">视频</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/content/contentEdit">视频</router-link>
            </a-menu-item>
          </a-menu> -->
        </a-dropdown>
      </div>
    </Breadcrumb>
    <!-- 查询 -->
    <a-form-model
      ref="formRef"
      layout="inline"
      :model="formState"
    >
      <a-form-model-item label="标题">
        <a-input v-model="formState.title" placeholder="输入文章标题" />
      </a-form-model-item>
      <a-form-model-item label="栏目">
        <a-tree-select
          v-model="formState.idNavigation"
          style="width: 180px;"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          placeholder="请选择"
          tree-default-expand-all
          :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
        >
          <span v-if="key === '0-0-1'" slot="name" slot-scope="{ key, value }" style="color: #08c">
            Child Node1 {{ value }}
          </span>
        </a-tree-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="作者">
        <a-input v-model="formState.author" placeholder="输入作者" />
      </a-form-model-item> -->
      <a-form-model-item label="发布状态">
        <a-select v-model="formState.releaseStatus" placeholder="请选择" style="width:180px;">
          <a-select-option :value=0>
            未发布
          </a-select-option>
          <a-select-option :value=1>
            已发布
          </a-select-option>
          <a-select-option :value=2>
            已下线
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-button @click="(e)=>{queryData()}" type="primary" html-type="submit">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <br />
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :scroll="{x: true, y: false}"
      :rowKey="(record) => record.id"
      bordered
    >
      <div slot="columnsName" slot-scope="text,record">
        <span v-for="item in record.navigationContentList" :key="item.idCmsNavigation">
            {{item.nameCmsNavigation}}
        </span>
      </div>
      <div slot="contentType" slot-scope="text,record">
          <div class="flex-row">
              <div style="display:flex; align-items:center; margin-right:20px; white-space: nowrap;">
                  {{ record.contentType | filterContentType }}
              </div>
              <div>
                  <image style="max-height:50px;" src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
              </div>
          </div>

      </div>
      <div slot="contentTitle" slot-scope="text,record">
          <strong>{{record.title}}</strong> <br/>
          {{record.titleSub}}
      </div>
      <div slot="viewStyle" slot-scope="text,record">
          {{
              record.viewStyle | filterContentViewStyle
          }}
      </div>
      <div slot="tags" slot-scope="text,record">
          <a-tag v-for="item in getTags(record.tags)" :key="item.index">
              {{item}}
          </a-tag>
      </div>
      <div slot="releaseStatus" slot-scope="text,record">
          {{
              record.releaseStatus | filterContentReleaseStatus
          }}
      </div>
      <div slot="times" slot-scope="text,record">
          创建：{{record.gmtCreate}}<br/>
          修改：{{record.gmtModified}}<br/>
          发布：{{record.gmtRelease}}
      </div>
      <div slot="action" slot-scope="record">
          <span>
          <a class="link-nowrap" @click="(()=>{editContent(record)})">修改</a>
          <a-divider type="vertical"/>
          <a-popconfirm
                  title="确定要删除此条信息吗?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="(()=>{del(record.id)})"
                  @cancel="cancel"
          >
              <a class="link-nowrap" href="#">删除</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a-popconfirm
                  v-if="record.releaseStatus===0 || record.releaseStatus===2"
                  title="确定要发布此条信息吗?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="(()=>{onlineContent(record.id,1)})"
                  @cancel="cancel"
          >
              <a href="#" class="link-nowrap">发布</a>
          </a-popconfirm>
          <a-popconfirm
                  v-if="record.releaseStatus===1"
                  title="确定要下线此条信息吗?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="(()=>{onlineContent(record.id,2)})"
                  @cancel="cancel"
          >
              <a href="#" class="link-nowrap">下线</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a class="link-nowrap" @click="sortContent(record.sort,record.id)">排序</a>
          <a-divider type="vertical"/>
          <a class="link-nowrap" @click="(()=>{top(record.id,record.top)})">
            {{record.top===0?'置顶内容':'取消置顶'}}
          </a>
          </span>
      </div>
    </a-table>
    <br />
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
    <Sort @sort="queryData(current)" ref="ContentSort"></Sort>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import Sort from './sort.vue'

export default {
  components: {
    Breadcrumb,
    Sort
  },
  data () {
    return {
      value: undefined,
      treeData: [],
      breadList: [
        {
          name: '内容管理',
          url: ''
        }
      ],
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          width: 150,
          ellipsis: true,
          key: 'id'
        },
        {
          title: '栏目',
          dataIndex: 'columnsName',
          key: 'columnsName',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'columnsName'
          }
        },
        // {
        //   title: '样式',
        //   dataIndex: 'viewStyle',
        //   key: 'viewStyle',
        //   scopedSlots: {
        //     customRender: 'viewStyle'
        //   }
        // },
        // {
        //   title: '类型',
        //   dataIndex: 'contentType',
        //   key: 'contentType',
        //   scopedSlots: {
        //     customRender: 'contentType'
        //   }
        // },
        {
          title: '状态',
          dataIndex: 'releaseStatus',
          key: 'releaseStatus',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'releaseStatus'
          }
        },
        {
          title: '标题',
          dataIndex: 'contentTitle',
          key: 'contentTitle',
          scopedSlots: {
            customRender: 'contentTitle'
          }
        },

        // {
        //   title: '简介',
        //   dataIndex: 'intro',
        //   key: 'intro'
        // },
        // {
        //   title: '作者',
        //   dataIndex: 'author',
        //   key: 'author'
        // },
        {
          title: '时间',
          dataIndex: 'times',
          key: 'times',
          width: 180,
          ellipsis: true,
          scopedSlots: {
            customRender: 'times'
          }
        },
        {
          title: '操作',
          key: 'action',
          width: 350,
          ellipsis: true,
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      rules: {
        name: [{ required: true, message: 'adadsf', trigger: 'blur' }],
        region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }]
      },
      visible: false,
      formState: {
        title: '',
        idNavigation: undefined,
        author: undefined,
        releaseStatus: undefined

      },
      pageTypeList: [
        {
          value: 'abc',
          label: '磊在'
        }
      ],
      data: [],
      total: 0,
      pageSize: 10,
      current: 1
    }
  },
  created () {
    this.queryData()
    this.queryTreeData()
  },
  methods: {
    sortContent (sortNum, id) {
      this.$refs.ContentSort.init(sortNum, id)
    },
    preViewContent (id) {
      this.$refs.refPreview.init({ id })
    },
    async top (id, status) {
      const top = status === 0 ? 1 : 0
      const res = await this.$store.dispatch('http', {
        api: 'topContent',
        query: {
          id, status: top
        }
      })
      if (res) {
        this.$store.dispatch('showToast', { message: '操作成功' })
        this.queryData(this.current)
      }
    },
    async onlineContent (id, status) {
      const res = await this.$store.dispatch('http', {
        api: 'onlineContent',
        query: {
          id, status
        }
      })
      if (res) {
        this.$store.dispatch('showToast', { message: '操作成功' })
        this.queryData(this.current)
      }
    },
    async del (id) {
      const res = await this.$store.dispatch('http', {
        api: 'deleteContent',
        query: {
          id
        }
      })
      if (res) {
        this.$store.dispatch('showToast', { message: '删除成功' })
        this.queryData(this.current)
      }
    },
    editContent (record) {
      this.$store.dispatch('setFormSearch', this.formState)
      switch (record.viewStyle) {
        case 'VS1': {
          this.$router.push({ path: '/content/mvEdit', query: { id: record.id } })
          break
        }
        default: {
          this.$router.push({ path: '/content/addEdit', query: { id: record.id } })
        }
      }
    },
    async queryTreeData () {
      const res = await this.$store.dispatch('http', {
        api: 'navagationTree',
        params: { navigationType: 'cms' }
      })
      console.log(res)
      this.treeData = res
    },
    getTags (tags) {
      return tags.split(',')
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      const res = await this.$store.dispatch('http', {
        api: 'searchContent',
        // api: 'navagationTree',
        params: {
          ...this.formState,
          pageNo: this.current,
          pageSize: this.pageSize
          // navigationType: 'cms'
        }
      })
      console.log(res, 'content data')

      this.data = res.records
      this.total = res.total
      this.$forceUpdate()
    },
    handleFinish () {
    },
    handleFinishFailed () {
      console.log()
    },
    resetForm () {
      this.formState = {
        name: '',
        pageType: undefined
      }
      this.queryData()
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 是否确认弹窗口  方法
    confirm (values) {
      console.log(values)
    },
    cancel (values) {
      console.log(values)
    }
  }
}
</script>

<style>
</style>
